var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('main',[_c('div',{staticClass:"container-fluid px-4"},[_c('div',{staticClass:"card mb-4 mt-4"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-3"},[_c('h6',[_vm._v("Detail")]),_c('table',{staticClass:"tables table-bordered"},[_c('tr',[_c('td',{staticStyle:{"width":"300px"}},[_vm._v("Daerah Irigasi")]),_c('td',{staticStyle:{"width":"5px"}},[_vm._v(":")]),_c('td',[_vm._v(_vm._s(_vm.detail.daerah_irigasi))])]),_c('tr',[_c('td',[_vm._v("Tahun Survei")]),_c('td',[_vm._v(":")]),_c('td',[_vm._v(_vm._s(_vm.detail.tahun_survey))])]),_c('tr',[_c('td',[_vm._v("Usulan Pemeliharaan Berkala Irigasi")]),_c('td',[_vm._v(":")]),_c('td',[_vm._v(_vm._s(_vm.detail.usulan_berkala))])])])]),_vm._m(0),_c('div',{staticClass:"col-md-12"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"lineNumbers":true,"search-options":{
                  enabled: true,
                  placeholder: 'Cari',
                },"pagination-options":{
                  enabled: true,
                  mode: 'records',
                  perPage: 9,
                  perPageDropdownEnabled: false,
                  position: 'bottom',
                  nextLabel: '',
                  prevLabel: '',
                  ofLabel: 'of',
                  pageLabel: 'page',
                  allLabel: 'All',
                }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'tanggal')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDateTime(props.row.created_at))+" ")]):_vm._e(),(props.column.field == 'action')?_c('span',[_c('div',[_c('router-link',{staticClass:"btn btn-sm btn-success",staticStyle:{"margin-right":"5px"},attrs:{"to":'/galian-endapan/foto/' +
                          props.row.id_galian_endapan}},[_c('font-awesome-icon',{attrs:{"icon":"search"}})],1),(_vm.userLevel == 'admin')?_c('button',{staticClass:"btn btn-sm btn-danger",on:{"click":function($event){return _vm.confirmDelete(props.row.id_galian_endapan)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash-alt"}})],1):_vm._e()],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.tableInfo)+" ")]),_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticStyle:{"display":"flex"}},[_c('button',{staticClass:"btn btn-sm btn-light",staticStyle:{"margin-right":"5px"},attrs:{"type":"button"},on:{"click":function($event){return _vm.fetchData(_vm.id)}}},[_c('font-awesome-icon',{attrs:{"icon":"sync","spin":_vm.loading}})],1)])])])],1)])]),_vm._m(1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('h6',[_vm._v("Maps")]),_c('div',{staticStyle:{"height":"400px"},attrs:{"id":"map"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-footer"},[_c('a',{staticClass:"btn btn-secondary",attrs:{"href":"javascript:window.history.go(-1);"}},[_vm._v("Kembali")])])
}]

export { render, staticRenderFns }