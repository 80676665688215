<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <div class="card mb-4 mt-4">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mb-3">
                <h6>Detail</h6>
                <table class="tables table-bordered">
                  <tr>
                    <td style="width: 300px">Daerah Irigasi</td>
                    <td style="width: 5px">:</td>
                    <td>{{ detail.daerah_irigasi }}</td>
                  </tr>
                  <tr>
                    <td>Tahun Survei</td>
                    <td>:</td>
                    <td>{{ detail.tahun_survey }}</td>
                  </tr>
                </table>
              </div>
              <div class="col-md-12">
                <h6>Maps</h6>
                <!-- <div v-if="loading" class="skeleton-screen"></div> -->
                <div id="map" style="height: 400px"></div>
              </div>

              <div class="col-md-12">
                <vue-good-table
                  :columns="columns"
                  :rows="rows"
                  :lineNumbers="true"
                  :search-options="{
                    enabled: true,
                    placeholder: 'Cari',
                  }"
                  :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    perPage: 9,
                    perPageDropdownEnabled: false,
                    position: 'bottom',
                    nextLabel: '',
                    prevLabel: '',
                    ofLabel: 'of',
                    pageLabel: 'page',
                    allLabel: 'All',
                  }"
                >
                  <div slot="emptystate" class="text-center">
                    {{ tableInfo }}
                  </div>
                  <div slot="table-actions">
                    <div style="display: flex">
                      <button
                        type="button"
                        v-on:click="fetchData(id)"
                        class="btn btn-sm btn-light"
                        style="margin-right: 5px"
                      >
                        <font-awesome-icon icon="sync" :spin="loading" />
                      </button>
                    </div>
                  </div>
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'tanggal'">
                      {{ formatDateTime(props.row.created_at) }}
                    </span>
                    <span v-if="props.column.field == 'action'">
                      <div>
                        <router-link
                          :to="'/tanaman/foto/' + props.row.id_tanaman"
                          class="btn btn-sm btn-success"
                          style="margin-right: 5px"
                        >
                          <font-awesome-icon icon="search" />
                        </router-link>
                        <button
                          v-if="userLevel == 'admin'"
                          class="btn btn-sm btn-danger"
                          v-on:click="confirmDelete(props.row.id_tanaman)"
                        >
                          <font-awesome-icon icon="trash-alt" />
                        </button>
                      </div>
                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <a
              href="javascript:window.history.go(-1);"
              class="btn btn-secondary"
              >Kembali</a
            >
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import L from "leaflet";

export default {
  name: "DetailTanaman",
  data() {
    return {
      columns: [
        {
          label: "Nama Ruas",
          field: "nama_petak",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Nama Surveyor",
          field: "nama_surveyor",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Tanggal",
          field: "tanggal",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      tableInfo: "Tidak ada data yang dicari",
      detail: {
        longitude: "",
        latitude: "",
        usulan_berkala: "",
        nama_saluran: "",
        foto_lokasi: "",
        file_sketsa: "",
        nama_surveyor: "",
        daerah_irigasi: "",
        tahun_survey: "",
        panjang: "",
        informasi_tambahan: "",
      },
      id: "",
      list: [],
      loading: true,
      linkImage: "",
    };
  },
  created() {
    const user = localStorage.getItem("surveillanceData");
    if (user) {
      this.user = JSON.parse(user);
      this.userLevel = this.user.level;
    }
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    const id = this.$route.params.id;
    this.id = id;
    this.fetchData(id);
  },
  methods: {
    contentLoaded() {
      this.loading = false;
    },
    formatDateTime(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    fetchData(param) {
      axios
        .get("/v1/tanaman/detail/" + param)
        .then((response) => {
          for (let key in this.detail) {
            this.detail[key] = response.data.data[key];
          }
          this.list = response.data.list;
          this.rows = response.data.list;
          this.tableInfo = "Tidak ada data yang dicari";
          this.loading = false;
          this.createMap();
        })
        .catch((error) => {
          this.$router.go(-1);
          console.log(error);
          this.error = error.message;
        });
    },
    createMap() {
      const map = L.map("map").setView(
        [this.detail.latitude, this.detail.longitude],
        13
      );

      let g_street = "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}";

      const customIcon = L.icon({
        iconUrl: "https://api.demoku.web.id/marker-galian.png",
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32],
      });

      L.tileLayer(g_street, {
        attribution: "",
        maxZoom: 25,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      }).addTo(map);

      const markerCoords = [];

      this.list.forEach((item) => {
        const coords = [item.latitude, item.longitude];
        markerCoords.push(coords);

        const marker = L.marker(
          coords,
          { icon: customIcon } // Menggunakan ikon kustom
        ).addTo(map);

        const popupContent = `
          <strong>Nama Ruas : ${item.nama_petak}</strong><br>
          <strong>Nama Surveyor : ${item.nama_surveyor}</strong><br>
        `;

        marker.bindPopup(popupContent).openPopup();
      });

      L.polyline(markerCoords, { color: "#0D6EFD" }).addTo(map);
    },

    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/tanaman/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData(this.id);
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style>
/* Atur gaya peta di sini jika diperlukan */
#map {
  height: 100px;
  width: 100%;
}
.tables {
  width: 100%;
}
.tables tr td {
  padding: 10px;
}
.img-box {
  width: 100%;
  height: 350px;
  overflow: hidden;
}
.skeleton-screen {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>