<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <div class="card mb-4 mt-4">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mb-3">
                <h6>Detail</h6>
                <table class="tables table-bordered">
                  <tr>
                    <td style="width: 300px">Nama Surveyor</td>
                    <td style="width: 10px">:</td>
                    <td>{{ detail.nama_surveyor }}</td>
                  </tr>
                  <tr>
                    <td style="width: 300px">Daerah Irigasi</td>
                    <td style="width: 5px">:</td>
                    <td>{{ detail.daerah_irigasi }}</td>
                  </tr>
                  <tr>
                    <td>Tahun Survei</td>
                    <td>:</td>
                    <td>{{ detail.tahun_survey }}</td>
                  </tr>
                  <tr>
                    <td>Informasi Tambahan</td>
                    <td>:</td>
                    <td>{{ detail.informasi_tambahan }}</td>
                  </tr>
                </table>
              </div>
              <div class="col-md-12">
                <h6>Foto & Video Bangunan</h6>
                <div class="row">
                  <div class="col-md-3">
                    <video
                      :src="detail.video"
                      controls
                      style="width: 100%; border-radius: 10px"
                    ></video>
                  </div>
                  <div
                    class="col-md-3"
                    v-for="(image, index) in list"
                    :key="index"
                  >
                    <img :src="image.link_foto" style="width: 100%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <a
              href="javascript:window.history.go(-1);"
              class="btn btn-secondary"
              >Kembali</a
            >
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "BangunanIrigasi",
  data() {
    return {
      detail: {
        longitude: "",
        latitude: "",
        usulan_berkala: "",
        nama_bangunan: "",
        foto_lokasi: "",
        video: "",
        nama_surveyor: "",
        daerah_irigasi: "",
        tahun_survey: "",
        informasi_tambahan: "",
      },
      id: "",
      list: [],
      loading: true,
      linkImage: "",
    };
  },
  created() {
    const user = localStorage.getItem("surveillanceData");
    if (user) {
      this.user = JSON.parse(user);
      this.userLevel = this.user.level;
    }
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    const id = this.$route.params.id;
    this.id = id;
    this.fetchData(id);
  },
  methods: {
    contentLoaded() {
      this.loading = false;
    },
    formatDateTime(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    fetchData(param) {
      axios
        .get("/v1/foto_video/detail/" + param)
        .then((response) => {
          for (let key in this.detail) {
            this.detail[key] = response.data.data[key];
          }
          this.list = response.data.list;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>

<style>
/* Atur gaya peta di sini jika diperlukan */
#map {
  height: 100px;
  width: 100%;
}
.tables {
  width: 100%;
}
.tables tr td {
  padding: 10px;
}
.img-box {
  width: 100%;
  height: 350px;
  overflow: hidden;
}
.skeleton-screen {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>