<template>
  <div>
    <div class="header align-items-center justify-content-center">
      <router-link class="btn" to="/login">
        <font-awesome-icon icon="sign-in" />
      </router-link>
      <div class="text-center p-4">
        <img src="../../assets/logo-sulteng.png" width="100px" />
        <p class="fs-2 mb-0">IRIGASIKU</p>
        <p class="fs-5 mb-0">{{ subtitle }}</p>
        <p class="fs-4 mb-1">CIKASDA SULTENG</p>
      </div>
    </div>
    <div class="header-second"></div>
  </div>
</template>
<script>
export default {
  name: "HeaderPage",

  data() {
    return {
      subtitle: "",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newValue) {
        const parts = newValue.split("/");
        const link = parts[1];
        if (link == "formulir-1") {
          this.subtitle = "Usulan Perbaikan Bangunan Irigasi";
        } else if (link == "formulir-2") {
          this.subtitle = "Usulan Perbaikan Saluran";
        } else if (link == "formulir-3") {
          this.subtitle = "Usulan Jalan Inspeksi";
        } else if (link == "formulir-4") {
          this.subtitle = "Usulan Galian Endapan";
        } else if (link == "formulir-5") {
          this.subtitle = "Tanaman";
        } else if (link == "formulir-6") {
          this.subtitle = "Foto & Video";
        }
      },
    },
  },
};
</script>
<style scoped>
.header {
  background: #0099ff;
  background: linear-gradient(to top, #0099ff 0%, #2968a0 100%);
  color: #fff;
  font-weight: bolder;
}
.header-second {
  height: 150px;
  background-color: #0099ff;
  border-radius: 0px 0px 50% 50%;
}
.btn {
  position: absolute;
  margin: 15px;
  right: 0;
}
</style>