<template>
  <div>
    <main>
      <div class="container-fluid px-4 mt-4">
        <div
          class="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          <h5>Forbidden</h5>
          <span>Anda tidak memiliki hak akses ke halaman ini</span>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "IndexPage",
};
</script>