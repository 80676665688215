<template>
  <div style="background-color: aliceblue">
    <Header></Header>
    <main>
      <div class="p-4">
        <audio ref="shutter">
          <source src="../../assets/shutter.mp3" type="audio/mpeg" />
        </audio>
        <div
          class="row align-items-center justify-content-center"
          style="margin-top: -180px"
        >
          <div class="col-md-6 bg-white p-4 rounded-5 shadow-lg box">
            <h4 class="text-center">Formulir Identifikasi Irigasi</h4>
            <p class="mb-4 text-center">Usulan Luas Tanam</p>
            <hr />
            <div class="row mb-3">
              <div class="col-md-4">
                <label>Address Map Locator</label>
              </div>
              <div class="col-md-8">
                <table class="table table-bordered">
                  <tr>
                    <td style="width: 100px">Longitude</td>
                    <td style="width: 5px">:</td>
                    <td>
                      <span v-if="form.longitude">
                        {{ form.longitude }}
                      </span>
                      <span v-else class="skeleton-loader"></span>
                    </td>
                  </tr>
                  <tr>
                    <td>Latitude</td>
                    <td>:</td>
                    <td>
                      <span v-if="form.latitude">
                        {{ form.latitude }}
                      </span>
                      <span v-else class="skeleton-loader"></span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <hr />
            <h4 class="text-center">{{ nama_daerah_irigasi }}</h4>
            <p class="mb-4 text-center">Tahun {{ form.tahun_survey }}</p>
            <hr />
            <div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>Nama Petak Tersier</label>
                </div>
                <div class="col-md-8">
                  <input
                    type="text"
                    v-model="form.nama_petak"
                    class="form-control"
                    :class="{
                      ' is-invalid': formValidate.nama_petak === false,
                      ' is-valid': formValidate.nama_petak === true,
                    }"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>Periode Tanam</label>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-12 mb-1">
                      <div class="input-group">
                        <span class="input-group-text" style="width: 75px"
                          >Awal</span
                        >
                        <select
                          v-model="form.bulan_awal"
                          class="form-select"
                          :class="{
                            ' is-invalid': formValidate.bulan_awal === false,
                            ' is-valid': formValidate.bulan_awal === true,
                          }"
                        >
                          <option value="">Bulan</option>
                          <option
                            v-for="(month, index) in months"
                            :key="index"
                            :value="month"
                          >
                            {{ month }}
                          </option>
                        </select>
                        <select
                          v-model="form.tahun_awal"
                          class="form-select"
                          :class="{
                            ' is-invalid': formValidate.tahun_awal === false,
                            ' is-valid': formValidate.tahun_awal === true,
                          }"
                        >
                          <option value="">Tahun</option>
                          <option
                            v-for="year in availableCurrentYears"
                            :key="year"
                            :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="input-group">
                        <span class="input-group-text" style="width: 75px"
                          >Akhir</span
                        >
                        <select
                          v-model="form.bulan_akhir"
                          class="form-select"
                          :class="{
                            ' is-invalid': formValidate.bulan_akhir === false,
                            ' is-valid': formValidate.bulan_akhir === true,
                          }"
                        >
                          <option value="">Bulan</option>
                          <option
                            v-for="(month, index) in months"
                            :key="index"
                            :value="month"
                          >
                            {{ month }}
                          </option>
                        </select>
                        <select
                          v-model="form.tahun_akhir"
                          class="form-select"
                          :class="{
                            ' is-invalid': formValidate.tahun_akhir === false,
                            ' is-valid': formValidate.tahun_akhir === true,
                          }"
                        >
                          <option value="">Tahun</option>
                          <option
                            v-for="year in availableCurrentYears"
                            :key="year"
                            :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>Luas Tanam</label>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="input-group mb-1">
                        <span class="input-group-text" style="width: 100px"
                          >Padi</span
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.luas_padi"
                        />
                        <span class="input-group-text">Ha</span>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="input-group mb-1">
                        <span class="input-group-text" style="width: 100px"
                          >Palawija</span
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.luas_palawija"
                        />
                        <span class="input-group-text">Ha</span>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="input-group mb-1">
                        <span class="input-group-text" style="width: 100px"
                          >Kolam</span
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.luas_kolam"
                        />
                        <span class="input-group-text">Ha</span>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="input-group mb-1">
                        <span class="input-group-text" style="width: 100px"
                          >Lainnya</span
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.luas_lainnya"
                        />
                        <span class="input-group-text">Ha</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>Foto Lokasi</label>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-12">
                      <button
                        v-if="!cameraOneOpen"
                        type="button"
                        class="btn btn-dark"
                        :class="{
                          'is-primary': !kameraFotoLokasi,
                          'is-danger': kameraFotoLokasi,
                        }"
                        @click="startCamera(1)"
                      >
                        <span>
                          <font-awesome-icon icon="camera" />
                          Buka Kamera</span
                        >
                      </button>
                      <button
                        v-else
                        type="button"
                        class="btn btn-dark"
                        :class="{
                          'is-primary': !kameraFotoLokasi,
                          'is-danger': kameraFotoLokasi,
                        }"
                        @click="stopCamera(1)"
                      >
                        <span>
                          <font-awesome-icon icon="times" />
                          Tutup Kamera</span
                        >
                      </button>
                    </div>
                    <div v-show="kameraFotoLokasi" class="mt-2 col-md-12">
                      <canvas ref="canvasLokasi" style="display: none"></canvas>
                      <video
                        style="width: 100%"
                        v-show="kameraFotoLokasi"
                        ref="kameraLokasi"
                        autoplay
                      ></video>
                    </div>
                    <div v-if="cameraOneOpen" class="col-md-12">
                      <div class="d-inline-flex d-grid gap-2">
                        <button
                          @click="takePicture(1)"
                          type="button"
                          class="btn btn-dark"
                        >
                          <font-awesome-icon icon="camera" /> Ambil Gambar
                        </button>
                        <!-- <button
                          @click="toggleCamera(1)"
                          type="button"
                          class="btn btn-dark"
                        >
                          <font-awesome-icon icon="camera-rotate" /> Putar Kamera
                        </button> -->
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div v-if="hasilFotoLokasi.length > 0" class="row mt-1">
                        <div
                          class="col-md-12"
                          v-for="(image, index) in hasilFotoLokasi"
                          :key="index"
                        >
                          <div class="image-groups">
                            <div class="image-container">
                              <img
                                :src="image.url"
                                :alt="image.name"
                                class="image-item"
                              />
                              <span>{{ image.name }}</span>
                            </div>
                            <div class="button-container">
                              <button
                                class="tombol-hapus"
                                @click="deletePicture(index, 1)"
                              >
                                <font-awesome-icon icon="times" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>Informasi Tambahan</label>
                </div>
                <div class="col-md-8">
                  <textarea
                    v-model="form.informasi_tambahan"
                    class="form-control"
                    rows="4"
                  ></textarea>
                </div>
              </div>
              <hr />
              <p class="mb-4 text-center" v-if="dataOffline.length > 0">
                Data Belum Terkirim
              </p>
              <div class="col-md-12">
                <div v-if="dataOffline.length > 0" class="row mt-1">
                  <div
                    class="col-md-12"
                    v-for="item in dataOffline"
                    :key="item.id"
                  >
                    <div class="image-groups">
                      <div class="image-container">
                        <img
                          :src="item.foto_lokasi[0]"
                          :alt="item.nama_bangunan"
                          class="image-item"
                        />
                      </div>
                      <div
                        style="
                          width: 100%;
                          margin-left: 10px;
                          margin-right: 10px;
                        "
                      >
                        <span>
                          {{ item.nama_petak }}
                        </span>
                      </div>
                      <div class="button-container">
                        <button
                          class="tombol-kirim"
                          @click="sendToServer(item, item.id)"
                        >
                          <font-awesome-icon
                            v-if="prosesKirim[item.id]"
                            icon="circle-notch"
                            :spin="true"
                          />
                          <font-awesome-icon v-else icon="paper-plane" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-12">
                  <a
                    href="javascript:window.history.go(-1);"
                    class="btn btn-secondary"
                  >
                    Kembali
                  </a>
                  <button
                    @click="submitForm()"
                    class="btn btn-primary float-end"
                    type="button"
                    :disabled="this.loading"
                  >
                    <font-awesome-icon
                      v-if="this.loading"
                      icon="circle-notch"
                      :spin="true"
                    />
                    Kirim
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      form: {
        longitude: "",
        latitude: "",
        usulan_berkala: "Tanaman",
        nama_petak: "",
        nama_surveyor: "",
        daerah_irigasi: "",
        tahun_survey: "",
        informasi_tambahan: "",
        tahun_awal: "",
        bulan_awal: "",
        tahun_akhir: "",
        bulan_akhir: "",
        luas_padi: "",
        luas_palawija: "",
        luas_kolam: "",
        luas_lainnya: "",
      },
      nama_daerah_irigasi: "",
      formValidate: [],
      loading: false,
      kameraFotoLokasi: null,
      kameraFotoSketsa: null,
      hasilFotoLokasi: [],
      hasilFotoSketsa: [],
      currentCamera: "environment",
      cameraOneOpen: false,
      cameraTwoOpen: false,
      listBangunan: [],
      dataOffline: [],
      prosesKirim: {},
    };
  },
  created() {
    this.loadFormFromLocalStorage();
    this.fetchIrigasi();
  },
  mounted() {
    this.getLocation();
    this.loadDataOffline();
  },
  methods: {
    loadDataOffline() {
      this.dataOffline = [];
      const request = indexedDB.open("dbFormE", 1);
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("formulirE")) {
          db.createObjectStore("formulirE", {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      };
      request.onsuccess = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("formulirE")) {
          db.createObjectStore("formulirE", {
            keyPath: "id",
            autoIncrement: true,
          });
        }
        const transaction = db.transaction(["formulirE"], "readwrite");
        const objectStore = transaction.objectStore("formulirE");
        const cursorRequest = objectStore.openCursor();
        cursorRequest.onsuccess = (event) => {
          const cursor = event.target.result;
          if (cursor) {
            this.dataOffline.push(cursor.value);
            cursor.continue();
          }
        };
      };
    },
    sendToServer(item, id) {
      this.$set(this.prosesKirim, id, true);
      const formData = new FormData();
      formData.append("longitude", item.longitude);
      formData.append("latitude", item.latitude);
      formData.append("usulan_berkala", item.usulan_berkala);
      formData.append("nama_petak", item.nama_petak);
      formData.append("bulan_awal", item.bulan_awal);
      formData.append("tahun_awal", item.tahun_awal);
      formData.append("bulan_akhir", item.bulan_akhir);
      formData.append("tahun_akhir", item.tahun_akhir);
      formData.append("luas_padi", item.luas_padi);
      formData.append("luas_palawija", item.luas_palawija);
      formData.append("luas_kolam", item.luas_kolam);
      formData.append("luas_lainnya", item.luas_lainnya);
      formData.append("nama_surveyor", item.nama_surveyor);
      formData.append("daerah_irigasi", item.daerah_irigasi);
      formData.append("tahun_survey", item.tahun_survey);
      formData.append("panjang", item.panjang);
      formData.append("informasi_tambahan", item.informasi_tambahan);
      if (item.file_sketsa) {
        formData.append("file_sketsa", item.file_sketsa);
      }
      if (item.foto_lokasi.length > 0) {
        for (var i = 0; i < item.foto_lokasi.length; i++) {
          formData.append("foto_lokasi[]", item.foto_lokasi[i]);
        }
      }

      axios
        .post("/v1/tanaman/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.status);
          this.$set(this.prosesKirim, id, false);
          // console.log(response.data.message);
          Swal.fire({
            title: "Sukses",
            text: "Data Berhasil Dikirim",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
          });
          setTimeout(() => {
            Swal.close();
            this.deleteData(id);
          }, 700);
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    deleteData(itemId) {
      const dbName = "dbFormE";
      const request = indexedDB.open(dbName, 1);
      const vueInstance = this;

      request.onsuccess = (event) => {
        const db = event.target.result;

        const transaction = db.transaction(["formulirE"], "readwrite");
        const objectStore = transaction.objectStore("formulirE");

        const deleteRequest = objectStore.delete(itemId);

        deleteRequest.onsuccess = (event) => {
          console.log(event);
          console.log("Data berhasil dihapus dari IndexedDB.");
          vueInstance.loadDataOffline();
        };

        deleteRequest.onerror = (event) => {
          console.error(
            "Gagal menghapus data dari IndexedDB:",
            event.target.error
          );
        };
      };
    },
    submitForm() {
      this.loading = true;
      if (navigator.onLine) {
        this.sendOnline();
      } else {
        this.saveOffline();
      }
    },
    saveOffline() {
      const dbFormE = {
        longitude: this.form.longitude,
        latitude: this.form.latitude,
        usulan_berkala: this.form.usulan_berkala,
        nama_petak: this.form.nama_petak,
        bulan_awal: this.form.bulan_awal,
        tahun_awal: this.form.tahun_awal,
        bulan_akhir: this.form.bulan_akhir,
        tahun_akhir: this.form.tahun_akhir,
        luas_padi: this.form.luas_padi,
        luas_palawija: this.form.luas_palawija,
        luas_kolam: this.form.luas_kolam,
        luas_lainnya: this.form.luas_lainnya,
        nama_surveyor: this.form.nama_surveyor,
        daerah_irigasi: this.nama_daerah_irigasi,
        tahun_survey: this.form.tahun_survey,
        informasi_tambahan: this.form.informasi_tambahan,
        file_sketsa: null,
        foto_lokasi: [],
      };
      if (this.hasilFotoSketsa.length > 0) {
        dbFormE.file_sketsa = this.hasilFotoSketsa[0].url;
      }
      if (this.hasilFotoLokasi.length > 0) {
        this.hasilFotoLokasi.forEach((foto) => {
          dbFormE.foto_lokasi.push(foto.url);
        });
      }
      const dbName = "dbFormE";
      const request = indexedDB.open(dbName, 1);
      const vueInstance = this;
      request.onupgradeneeded = function (event) {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("formulirE")) {
          const objectStore = db.createObjectStore("formulirE", {
            keyPath: "id",
            autoIncrement: true,
          });
          console.log(objectStore);
        }
      };
      request.onsuccess = function (event) {
        const db = event.target.result;
        const transaction = db.transaction(["formulirE"], "readwrite");
        const objectStore = transaction.objectStore("formulirE");
        objectStore.add(dbFormE).onsuccess = function (event) {
          console.log(event);
          console.log("Data berhasil disimpan di IndexedDB.");
        };
        transaction.oncomplete = function () {
          console.log("Transaksi selesai.");
          vueInstance.loading = false;
          Swal.fire({
            title: "Sukses",
            text: "Data Disimpan Secara Offline",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
          });
          setTimeout(() => {
            Swal.close();
            vueInstance.$router.go(-1);
          }, 700);
        };
        transaction.onerror = function (event) {
          console.error(
            "Gagal menyimpan data di IndexedDB:",
            event.target.error
          );
        };
      };
      request.onerror = function (event) {
        console.error("Gagal membuka database:", event.target.error);
      };
    },
    sendOnline() {
      this.loading = true;
      const formData = new FormData();
      formData.append("longitude", this.form.longitude);
      formData.append("latitude", this.form.latitude);
      formData.append("usulan_berkala", this.form.usulan_berkala);
      formData.append("nama_petak", this.form.nama_petak);
      formData.append("bulan_awal", this.form.bulan_awal);
      formData.append("tahun_awal", this.form.tahun_awal);
      formData.append("bulan_akhir", this.form.bulan_akhir);
      formData.append("tahun_akhir", this.form.tahun_akhir);
      formData.append("luas_padi", this.form.luas_padi);
      formData.append("luas_palawija", this.form.luas_palawija);
      formData.append("luas_kolam", this.form.luas_kolam);
      formData.append("luas_lainnya", this.form.luas_lainnya);
      formData.append("nama_surveyor", this.form.nama_surveyor);
      formData.append("daerah_irigasi", this.nama_daerah_irigasi);
      formData.append("tahun_survey", this.form.tahun_survey);
      formData.append("informasi_tambahan", this.form.informasi_tambahan);
      if (this.hasilFotoLokasi.length > 0) {
        for (var i = 0; i < this.hasilFotoLokasi.length; i++) {
          var foto = this.hasilFotoLokasi[i];
          formData.append("foto_lokasi[]", foto.url);
        }
      }

      axios
        .post("/v1/tanaman/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            // console.log(response.data.message);
            Swal.fire({
              title: "Sukses",
              text: "Data Berhasil Dikirim",
              icon: "success",
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              this.$router.go(-1);
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchIrigasi() {
      if (this.form.daerah_irigasi) {
        axios
          .get("/v1/master_bangunan/list/" + this.form.daerah_irigasi)
          .then((response) => {
            this.listBangunan = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.showError
        );
      } else {
        alert("Geolokasi tidak didukung di browser ini.");
      }
    },
    showPosition(position) {
      this.form.latitude = position.coords.latitude;
      this.form.longitude = position.coords.longitude;
    },
    showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          alert("Akses ke lokasi ditolak oleh pengguna.");
          break;
        case error.POSITION_UNAVAILABLE:
          alert("Informasi lokasi tidak tersedia.");
          break;
        case error.TIMEOUT:
          alert("Waktu permintaan lokasi habis.");
          break;
        case error.UNKNOWN_ERROR:
          alert("Terjadi kesalahan yang tidak diketahui.");
          break;
      }
    },
    loadFormFromLocalStorage() {
      const savedFormsData = localStorage.getItem("formsData");

      if (savedFormsData) {
        const formsData = JSON.parse(savedFormsData);
        this.form.nama_surveyor = formsData.nama_surveyor;
        this.form.daerah_irigasi = formsData.daerah_irigasi;
        this.nama_daerah_irigasi = formsData.nama_daerah_irigasi;
        this.form.tahun_survey = formsData.tahun_survey;
      }
    },
    async toggleCamera(param) {
      await this.stopCamera(param);
      this.currentCamera =
        this.currentCamera === "environment" ? "user" : "environment";
      await this.startCamera(param);
    },
    async startCamera(param) {
      try {
        if (param == 1) {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: this.currentCamera },
          });
          this.$refs.kameraLokasi.srcObject = stream;
          this.kameraFotoLokasi = stream;
          this.cameraOneOpen = true;
          this.stopCamera(2);
        } else {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: this.currentCamera },
          });
          this.$refs.kameraSketsa.srcObject = stream;
          this.kameraFotoSketsa = stream;
          this.cameraTwoOpen = true;
          this.stopCamera(1);
        }
      } catch (error) {
        console.error("Gagal membuka kamera:", error);
      }
    },
    stopCamera(param) {
      if (param == 1) {
        if (this.kameraFotoLokasi) {
          const tracks = this.kameraFotoLokasi.getTracks();
          tracks.forEach((track) => {
            track.stop();
            // track.enabled = false;
          });
          this.cameraOneOpen = false;
          this.kameraFotoLokasi = null;
        }
      }
      if (param == 2) {
        if (this.kameraFotoSketsa) {
          const tracks = this.kameraFotoSketsa.getTracks();
          tracks.forEach((track) => {
            track.stop();
            // track.enabled = false;
          });
          this.cameraTwoOpen = false;
          this.kameraFotoSketsa = null;
        }
      }
    },
    takePicture(param) {
      if (param === 1) {
        const video = this.$refs.kameraLokasi;
        const canvas = this.$refs.canvasLokasi;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        // Gambar video ke dalam canvas
        const context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        // Ambil data gambar dari canvas dan tambahkan ke daftar
        const imageDataURL = canvas.toDataURL("image/png");
        const timestamp = Date.now();
        const imageName = `gambar_${timestamp}.png`;

        this.hasilFotoLokasi.push({ url: imageDataURL, name: imageName });
      }
      if (param === 2) {
        const video2 = this.$refs.kameraSketsa;
        const canvas2 = this.$refs.canvasSketsa;
        canvas2.width = video2.videoWidth;
        canvas2.height = video2.videoHeight;

        // Gambar video ke dalam canvas
        const context2 = canvas2.getContext("2d");
        context2.drawImage(video2, 0, 0, video2.videoWidth, video2.videoHeight);

        // Ambil data gambar dari canvas dan tambahkan ke daftar
        const imageDataURL = canvas2.toDataURL("image/png");
        const timestamp = Date.now();
        const imageName = `gambar_${timestamp}.png`;

        // Memeriksa apakah objek ditemukan berdasarkan indeks
        if (this.hasilFotoSketsa.length > 0) {
          // Mengganti nilai objek dengan nilai baru
          this.hasilFotoSketsa.splice(0, 1);
          this.hasilFotoSketsa.push({ url: imageDataURL, name: imageName });
        } else {
          // Jika objek tidak ditemukan, menambahkan objek baru ke array
          this.hasilFotoSketsa.push({ url: imageDataURL, name: imageName });
        }
      }

      const audio = this.$refs.shutter;
      if (audio.paused) {
        audio.play();
      } else {
        audio.pause();
      }

      // Pastikan ukuran canvas sesuai dengan ukuran video
    },
    deletePicture(index, param) {
      if (param == 1) {
        this.hasilFotoLokasi.splice(index, 1);
      } else {
        this.hasilFotoSketsa.splice(index, 1);
      }
    },
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    availableCurrentYears() {
      const years = [];
      for (let year = this.currentYear; year <= this.currentYear + 5; year++) {
        years.push(year.toString());
      }
      return years;
    },
    availableYears() {
      const years = [];
      for (
        let year = this.currentYear + 5;
        year >= this.currentYear - 5;
        year--
      ) {
        years.push(year.toString());
      }
      return years;
    },
    months() {
      return [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
    },
  },
  beforeDestroy() {
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((track) => track.stop());
    }
  },
};
</script>

<style scoped>
.no-link {
  text-decoration: none;
}
.box {
  border-top: solid 5px #2968a0;
  border-bottom: solid 5px #0099ff;
}
.skeleton-loader {
  width: 100%;
  height: 20px;
  display: block;
  border-radius: 3px;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #d3d3d3;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}

.image-groups {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
}

/* Image styling */
.image-container {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
}

.image-item {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  object-fit: cover;
  margin-right: 5px;
}

.tombol-hapus {
  float: right;
  color: rgb(255, 0, 0);
  border-radius: 3px;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}
.tombol-kirim {
  float: right;
  color: #fff;
  border-radius: 3px;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0098fe;
}
</style>
