<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <form
          @submit.prevent="postData"
          id="formPeserta"
          enctype="multipart/form-data"
        >
          <div class="card mb-4 mt-4">
            <div class="card-header">Update Password</div>

            <div class="card-body">
              <div class="row">
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Password Lama</label>
                    <input
                      type="password"
                      class="form-control"
                      :class="formValidate.password_lama ? ' is-invalid' : ''"
                      name="password_lama"
                      v-model="form.password_lama"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.password_lama"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Password Baru</label>
                    <input
                      type="password"
                      class="form-control"
                      :class="formValidate.password_baru ? ' is-invalid' : ''"
                      name="password_baru"
                      v-model="form.password_baru"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.password_baru"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Ulangi Password Baru</label>
                    <input
                      type="password"
                      class="form-control"
                      :class="formValidate.password_ulang ? ' is-invalid' : ''"
                      name="password_ulang"
                      v-model="form.password_ulang"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.password_ulang"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button
                class="btn btn-sm btn-success float-end"
                @click="postData()"
                :disabled="this.loading"
              >
                Simpan
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "IndexPage",
  data() {
    return {
      form: {
        id_users: "",
        password_lama: "",
        password_baru: "",
        password_ulang: "",
      },
      formValidate: [],
      loading: false,
    };
  },
  mounted() {
    const id = this.$route.params.id;
    this.nomor_identitas = id;
    this.fetchData();
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();
      formData.append("id_users", this.form.id_users);
      formData.append("password_lama", this.form.password_lama);
      formData.append("password_baru", this.form.password_baru);
      formData.append("password_ulang", this.form.password_ulang);

      axios
        .post("/v1/users/password/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            // console.log(response.data.message);
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.$router.go(-1);
              //   this.$router.push("/pengguna");
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      axios
        .get("/v1/users/detail/" + this.nomor_identitas)
        .then((response) => {
          this.rows = response.data.data;
          this.form.id_users = response.data.data.id_users;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>