<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <div class="card mb-4 mt-4">
          <div class="card-header">
            <font-awesome-icon icon="table" />
            Saluran
          </div>
          <div class="card-body">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :lineNumbers="true"
              :search-options="{
                enabled: true,
                placeholder: 'Cari',
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 9,
                perPageDropdownEnabled: false,
                position: 'bottom',
                nextLabel: '',
                prevLabel: '',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: 'All',
              }"
            >
              <div slot="emptystate" class="text-center">
                {{ tableInfo }}
              </div>
              <div slot="table-actions">
                <div style="display: flex">
                  <button
                    type="button"
                    v-on:click="fetchData()"
                    class="btn btn-sm btn-light"
                    style="margin-right: 5px"
                  >
                    <font-awesome-icon icon="sync" :spin="loading" />
                  </button>
                  <button
                    type="button"
                    v-on:click="downloadExcel()"
                    class="btn btn-sm btn-success"
                    style="margin-right: 5px"
                  >
                    <i class="fa fa-file-excel d-md-none"></i>
                    <span class="d-none d-md-block">Export Excel</span>
                  </button>
                </div>
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                  <div>
                    <router-link
                      :to="'/saluran/detail/' + props.row.kode_survey"
                      class="btn btn-sm btn-success"
                      style="margin-right: 5px"
                    >
                      <font-awesome-icon icon="search" />
                    </router-link>
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
const ExcelJS = require("exceljs");

export default {
  name: "IndexPage",
  data() {
    return {
      columns: [
        {
          label: "Tahun",
          field: "tahun_survey",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Daerah Irigasi",
          field: "daerah_irigasi",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Usulan Berkala",
          field: "usulan_berkala",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      tableInfo: "Tidak ada data yang dicari",
      loading: false,
      userLevel: "user",
      userAkses: "",
    };
  },
  created() {
    const user = localStorage.getItem("surveillanceData");
    if (user) {
      this.user = JSON.parse(user);
      this.userLevel = this.user.level;
      if (this.user.irigasi) {
        this.userAkses = this.user.irigasi;
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.rows = [];
      this.tableInfo = "Memperbarui data";
      this.loading = true;
      axios
        .get("/v1/saluran/", {
          params: {
            irigasi: this.userAkses,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.tableInfo = "Tidak ada data yang dicari";
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/saluran/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    downloadExcel() {
      axios.get("/v1/saluran/ekspor").then((response) => {
        let data = response.data.data;

        let dataArray = data.map(async (item) => {
          return {
            Tahun: item.tahun_survey || " ",
            "Nama Surveyor": item.nama_surveyor || " ",
            "Daerah Irigasi": item.daerah_irigasi || " ",
            "Nama Saluran": item.nama_saluran || " ",
            "Usulan Berkala": item.usulan_berkala || " ",
            Longitude: item.longitude || " ",
            Latitude: item.latitude || " ",
            "Informasi Tambahan": item.informasi_tambahan || " ",
          };
        });

        Promise.all(dataArray)
          .then((completedArray) => {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("Saluran");

            const columnWidths = [
              { header: "Tahun", key: "Tahun", width: 7 },
              { header: "Nama Surveyor", key: "Nama Surveyor", width: 25 },
              { header: "Daerah Irigasi", key: "Daerah Irigasi", width: 25 },
              { header: "Nama Saluran", key: "Nama Saluran", width: 30 },
              {
                header: "Usulan Berkala",
                key: "Usulan Berkala",
                width: 30,
              },
              { header: "Longitude", key: "Longitude", width: 20 },
              { header: "Latitude", key: "Latitude", width: 20 },
              {
                header: "Informasi Tambahan",
                key: "Informasi Tambahan",
                width: 50,
              },
            ];

            worksheet.columns = columnWidths;

            // Mengisi data ke dalam worksheet seperti yang sudah Anda cantumkan
            completedArray.forEach((item) => {
              const row = worksheet.addRow(item);
              if (item.Sketsa) {
                const imageId = workbook.addImage({
                  base64: item.Sketsa,
                  extension: "png",
                });

                worksheet.addImage(imageId, {
                  tl: { col: columnWidths.length, row: row.number },
                  br: { col: columnWidths.length + 1, row: row.number + 1 },
                });
              }
            });

            // Mengatur header dengan font bold dan rata tengah (centered)
            const headerRow = worksheet.getRow(1);
            headerRow.eachCell((cell) => {
              cell.font = { bold: true };
              cell.alignment = { horizontal: "center" };
            });

            // Mengatur border pada sel-sel di worksheet
            const borderStyle = {
              style: "thin",
              color: { argb: "000000" }, // Hitam
            };

            worksheet.eachRow((row) => {
              row.eachCell((cell) => {
                cell.border = {
                  top: borderStyle,
                  left: borderStyle,
                  bottom: borderStyle,
                  right: borderStyle,
                };
              });
            });

            // Mengkonversi workbook menjadi bentuk binary (buffer)
            return workbook.xlsx.writeBuffer();
          })
          .then((excelBuffer) => {
            // Mengunduh file Excel
            this.saveAsExcelFile(excelBuffer, "saluran-irigasi.xlsx");
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      });
    },
    saveAsExcelFile(buffer, filename) {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      if (navigator.msSaveBlob) {
        // Untuk Internet Explorer atau Edge
        navigator.msSaveBlob(blob, filename);
      } else {
        // Untuk browser modern
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      }
    },
  },
};
</script>