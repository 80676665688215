var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('main',[_c('div',{staticClass:"container-fluid px-4"},[_c('div',{staticClass:"card mb-4 mt-4"},[_c('div',{staticClass:"card-header"},[_c('font-awesome-icon',{attrs:{"icon":"table"}}),_vm._v(" Galian Endapan ")],1),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"lineNumbers":true,"search-options":{
              enabled: true,
              placeholder: 'Cari',
            },"pagination-options":{
              enabled: true,
              mode: 'records',
              perPage: 9,
              perPageDropdownEnabled: false,
              position: 'bottom',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All',
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('div',[_c('router-link',{staticClass:"btn btn-sm btn-success",staticStyle:{"margin-right":"5px"},attrs:{"to":'/galian-endapan/detail/' + props.row.kode_survey}},[_c('font-awesome-icon',{attrs:{"icon":"search"}})],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.tableInfo)+" ")]),_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticStyle:{"display":"flex"}},[_c('button',{staticClass:"btn btn-sm btn-light",staticStyle:{"margin-right":"5px"},attrs:{"type":"button"},on:{"click":function($event){return _vm.fetchData()}}},[_c('font-awesome-icon',{attrs:{"icon":"sync","spin":_vm.loading}})],1),_c('button',{staticClass:"btn btn-sm btn-success",staticStyle:{"margin-right":"5px"},attrs:{"type":"button"},on:{"click":function($event){return _vm.downloadExcel()}}},[_c('i',{staticClass:"fa fa-file-excel d-md-none"}),_c('span',{staticClass:"d-none d-md-block"},[_vm._v("Export Excel")])])])])])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }