<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <form
          @submit.prevent="postData"
          id="formPeserta"
          enctype="multipart/form-data"
        >
          <div class="card mb-4 mt-4">
            <div class="card-header">Form Pengguna</div>

            <div class="card-body">
              <div class="row">
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>NIK</label>
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      :class="formValidate.nomor_identitas ? ' is-invalid' : ''"
                      name="nomor_identitas"
                      v-model="form.nomor_identitas"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.nomor_identitas"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Nama</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="formValidate.nama_lengkap ? ' is-invalid' : ''"
                      name="nama_lengkap"
                      v-model="form.nama_lengkap"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.nama_lengkap"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Telp/WA</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="formValidate.no_handphone ? ' is-invalid' : ''"
                      name="no_handphone"
                      v-model="form.no_handphone"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.no_handphone"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Username</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="formValidate.email ? ' is-invalid' : ''"
                      name="email"
                      v-model="form.email"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.email"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2" v-if="userLevel == 'admin'">
                  <div class="form-group">
                    <label>Level User</label>
                    <select
                      name="level"
                      class="form-select"
                      :class="formValidate.level ? ' is-invalid' : ''"
                      v-model="form.level"
                    >
                      <option value="admin">Admin</option>
                      <option value="user">User</option>
                    </select>
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.level"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2" v-if="form.level == 'user'">
                  <div class="form-group">
                    <label>Daerah Irigasi</label>
                    <select
                      v-model="form.irigasi"
                      class="form-select"
                      :class="{
                        ' is-invalid': formValidate.irigasi === false,
                        ' is-valid': formValidate.irigasi === true,
                      }"
                      v-on:change="handleChange($event)"
                    >
                      <option value="">Pilih Daerah Irigasi</option>
                      <option
                        v-for="daerah_irigasi in daerahIrigasi"
                        :key="daerah_irigasi.id_mst_daerah_irigasi"
                        :value="daerah_irigasi.nama_daerah_irigasi"
                        :data-key="daerah_irigasi.nama_daerah_irigasi"
                      >
                        {{ daerah_irigasi.nama_daerah_irigasi }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button
                class="btn btn-sm btn-success float-end"
                @click="postData()"
                :disabled="this.loading"
              >
                Simpan
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "IndexPage",
  data() {
    return {
      form: {
        id_users: "",
        nama_lengkap: "",
        nomor_identitas: "",
        no_handphone: "",
        email: "",
        old_email: "",
        password: "",
        confirm_password: "",
        level: "",
        irigasi: "",
      },
      formValidate: [],
      daerahIrigasi: [],
      loading: false,
      userLevel: "user",
    };
  },
  created() {
    const user = localStorage.getItem("surveillanceData");
    if (user) {
      this.user = JSON.parse(user);
      this.userLevel = this.user.level;
    }
  },
  mounted() {
    const id = this.$route.params.id;
    this.nomor_identitas = id;
    this.fetchData();
    this.fetchIrigasi();
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();
      formData.append("id_users", this.form.id_users);
      formData.append("nama_lengkap", this.form.nama_lengkap);
      formData.append("no_handphone", this.form.no_handphone);
      formData.append("email", this.form.email);
      formData.append("old_email", this.form.old_email);
      formData.append("nomor_identitas", this.form.nomor_identitas);
      formData.append("level", this.form.level);
      formData.append("irigasi", this.form.irigasi);

      axios
        .post("/v1/users/update/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              //   this.$router.push("/pengguna");
              this.$router.go(-1);
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      axios
        .get("/v1/users/detail/" + this.nomor_identitas)
        .then((response) => {
          this.rows = response.data.data;
          this.form.id_users = response.data.data.id_users;
          this.form.nomor_identitas = response.data.data.nomor_identitas;
          this.form.nama_lengkap = response.data.data.nama_lengkap;
          this.form.no_handphone = response.data.data.no_handphone;
          this.form.email = response.data.data.email;
          this.form.old_email = response.data.data.email;
          this.form.level = response.data.data.level;
          this.form.irigasi = response.data.data.irigasi;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchIrigasi() {
      axios
        .get("/v1/daerah_irigasi/")
        .then((response) => {
          this.daerahIrigasi = response.data.data;
          localStorage.setItem(
            "daerahIrigasi",
            JSON.stringify(response.data.data)
          );
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>