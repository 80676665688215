<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <h3 class="mt-4 mb-3">Dashboard</h3>
        <div class="row">
          <div class="col-xl-3 col-md-6">
            <div class="card bg-primary text-white mb-4">
              <div class="card-body">
                <span>Bangunan Irigasi</span>
                <br />
                <span>{{ detail.bangunan }}</span>
                <i class="icon-bendungan"></i>
              </div>
              <div
                class="card-footer d-flex align-items-center justify-content-between"
              >
                <router-link
                  :to="'/bangunan-irigasi/'"
                  class="small text-white stretched-link"
                >
                  View Details
                </router-link>
                <div class="small text-white">
                  <font-awesome-icon icon="angle-right" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="card bg-warning text-white mb-4">
              <div class="card-body">
                <span> Perbaikan Saluran</span><br />
                <span>{{ detail.saluran }}</span>
                <i class="icon-saluran"></i>
              </div>
              <div
                class="card-footer d-flex align-items-center justify-content-between"
              >
                <router-link
                  :to="'/saluran/'"
                  class="small text-white stretched-link"
                >
                  View Details
                </router-link>
                <div class="small text-white">
                  <font-awesome-icon icon="angle-right" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="card bg-success text-white mb-4">
              <div class="card-body">
                <span> Jalan Inspeksi</span><br />
                <span>{{ detail.jalan }}</span>
                <i class="icon-jalan"></i>
              </div>
              <div
                class="card-footer d-flex align-items-center justify-content-between"
              >
                <router-link
                  :to="'/jalan-inspeksi/'"
                  class="small text-white stretched-link"
                >
                  View Details
                </router-link>
                <div class="small text-white">
                  <font-awesome-icon icon="angle-right" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="card bg-danger text-white mb-4">
              <div class="card-body">
                <span>Galian Endapan</span><br />
                <span>{{ detail.galian }}</span>
                <i class="icon-galian"></i>
              </div>
              <div
                class="card-footer d-flex align-items-center justify-content-between"
              >
                <router-link
                  :to="'/galian-endapan/'"
                  class="small text-white stretched-link"
                >
                  View Details
                </router-link>
                <div class="small text-white">
                  <font-awesome-icon icon="angle-right" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "DashboardPage",
  data() {
    return {
      detail: {
        bangunan: "",
        saluran: "",
        jalan: "",
        galian: "",
      },
      loading: true,
      userLevel: "user",
      userAkses: "",
    };
  },
  created() {
    const user = localStorage.getItem("surveillanceData");
    if (user) {
      this.user = JSON.parse(user);
      this.userLevel = this.user.level;
      if (this.user.irigasi) {
        this.userAkses = this.user.irigasi;
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get("/v1/dashboard", {
          params: {
            irigasi: this.userAkses,
          },
        })
        .then((response) => {
          for (let key in this.detail) {
            this.detail[key] = response.data[key];
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>
<style scoped>
@font-face {
  font-family: "IrigationIcon";
  src: url("../assets/fonts/icomoon.woff") format("woff");
  /* tambahkan format lain jika diperlukan */
}
.card-body {
  height: 90px;
}

.card-body span {
  font-size: 20px;
  font-weight: bold;
}

.card-body i {
  font-size: 50px;
  float: right;
  margin-top: -35px;
}

.icon-saluran:before {
  font-family: "IrigationIcon";
  content: "\e900";
  font-style: normal;
}
.icon-bendungan:before {
  font-family: "IrigationIcon";
  content: "\e90e";
  font-style: normal;
}
.icon-galian:before {
  font-family: "IrigationIcon";
  content: "\e913";
  font-style: normal;
}
.icon-jalan:before {
  font-family: "IrigationIcon";
  content: "\e91e";
  font-style: normal;
}
.stretched-link {
  text-decoration: none;
}
</style>

