<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <div class="card mb-4 mt-4">
          <div class="card-header">
            <font-awesome-icon icon="table" />
            Daerah Irigasi
          </div>
          <div class="card-body">
            <form @submit.prevent="postData" enctype="multipart/form-data">
              <div class="row" v-if="openForm">
                <div class="col-md-9 mb-2">
                  <input type="hidden" v-model="form.id_mst_daerah_irigasi" />
                  <input
                    type="text"
                    v-model="form.nama_daerah_irigasi"
                    class="form-control form-control-sm"
                    placeholder="Nama Daerah Irigasi"
                    :class="{
                      ' is-invalid': formValidate.nama_daerah_irigasi,
                    }"
                  />
                </div>
                <div class="col-md-3 mb-2">
                  <div class="d-grid gap-2">
                    <button
                      class="btn btn-sm btn-primary"
                      type="button"
                      @click="postData()"
                      :disabled="this.loading"
                    >
                      <font-awesome-icon
                        v-if="loading"
                        icon="circle-notch"
                        :spin="true"
                      />
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :lineNumbers="true"
              :search-options="{
                enabled: true,
                placeholder: 'Cari',
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 10,
                perPageDropdownEnabled: false,
                position: 'bottom',
                nextLabel: '',
                prevLabel: '',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: 'All',
              }"
            >
              <div slot="emptystate" class="text-center">
                {{ tableInfo }}
              </div>
              <div slot="table-actions">
                <div style="display: flex">
                  <button
                    type="button"
                    v-on:click="fetchData()"
                    class="btn btn-sm btn-light"
                    style="margin-right: 5px"
                  >
                    <font-awesome-icon icon="sync" :spin="loading" />
                  </button>
                  <a
                    href="javascript:;"
                    @click="toggleForm()"
                    class="btn btn-sm btn-primary"
                    :class="{ 'btn-warning': openForm }"
                    style="margin-right: 5px"
                  >
                    <font-awesome-icon
                      icon="plus"
                      class="d-md-none"
                      :class="{ 'fa-times': openForm }"
                    />
                    <span class="d-none d-md-block">{{
                      openForm ? "Batal" : "Tambah Data"
                    }}</span>
                  </a>
                </div>
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                  <div>
                    <button
                      class="btn btn-sm btn-warning"
                      style="margin-right: 5px"
                      v-on:click="updateData(props.row.id_mst_daerah_irigasi)"
                    >
                      <font-awesome-icon icon="pencil-alt" />
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      v-on:click="
                        confirmDelete(props.row.id_mst_daerah_irigasi)
                      "
                    >
                      <font-awesome-icon icon="trash-alt" />
                    </button>
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "IndexPage",
  data() {
    return {
      columns: [
        {
          label: "Daerah Irigasi",
          field: "nama_daerah_irigasi",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      form: {
        id_mst_daerah_irigasi: "",
        nama_daerah_irigasi: "",
      },
      formValidate: [],
      link: "/v1/daerah_irigasi/create",
      tableInfo: "Tidak ada data yang dicari",
      loading: false,
      openForm: false,
    };
  },
  mounted() {
    this.fetchData();
    // this.loading = false;
  },
  methods: {
    fetchData() {
      this.rows = [];
      this.tableInfo = "Memperbarui data";
      this.loading = true;
      axios
        .get("/v1/daerah_irigasi/")
        .then((response) => {
          this.rows = response.data.data;
          this.tableInfo = "Tidak ada data yang dicari";
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/daerah_irigasi/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    postData() {
      this.loading = true;

      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.fetchData();
                this.resetForm();
                this.loading = false;
                this.openForm = false;
              }
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    updateData(id) {
      try {
        axios
          .get("/v1/daerah_irigasi/detail/" + id)
          .then((response) => {
            if (response.status === 200) {
              this.form.id_mst_daerah_irigasi =
                response.data.data.id_mst_daerah_irigasi;
              this.form.nama_dosen = response.data.data.nama_dosen;
              this.form.nama_daerah_irigasi =
                response.data.data.nama_daerah_irigasi;
              this.form.nomor_hp = response.data.data.nomor_hp;
              this.openForm = true;
              this.link = "/v1/daerah_irigasi/update";
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    toggleForm() {
      this.resetForm();
      this.link = "/v1/daerah_irigasi/create";
      this.openForm = !this.openForm;
    },
    resetForm() {
      this.form.id_mst_daerah_irigasi = "";
      this.form.nama_daerah_irigasi = "";
      this.loading = false;
      this.formValidate = [];
    },
  },
};
</script>