<template>
  <div style="background-color: aliceblue">
    <Header></Header>
    <main>
      <div class="p-4">
        <audio ref="shutter">
          <source src="../../assets/shutter.mp3" type="audio/mpeg" />
        </audio>
        <div
          class="row align-items-center justify-content-center"
          style="margin-top: -180px"
        >
          <div class="col-md-6 bg-white p-4 rounded-5 shadow-lg box">
            <h4 class="text-center">Formulir Identifikasi Irigasi</h4>
            <p class="mb-4 text-center">Usulan Pemeliharaan Berkala</p>
            <hr />
            <h4 class="text-center">{{ nama_daerah_irigasi }}</h4>
            <p class="mb-4 text-center">Tahun {{ form.tahun_survey }}</p>
            <hr />
            <div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>Foto</label>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-12">
                      <button
                        type="button"
                        class="btn btn-dark"
                        @click.prevent="showImageChooser"
                      >
                        <span>
                          <font-awesome-icon icon="image" />
                          Browse Foto</span
                        >
                      </button>
                      <input
                        style="display: none"
                        ref="input_foto"
                        type="file"
                        name="image"
                        accept="image/*"
                        @change="setImage"
                      />
                    </div>
                    <div class="col-md-12">
                      <div v-if="hasilFoto.length > 0" class="row mt-1">
                        <div
                          class="col-md-12"
                          v-for="(image, index) in hasilFoto"
                          :key="index"
                        >
                          <div class="image-groups">
                            <div class="image-container">
                              <img
                                :src="image.url"
                                :alt="image.name"
                                class="image-item"
                              />
                              <span>{{ image.name }}</span>
                            </div>
                            <div class="button-container">
                              <button
                                class="tombol-hapus"
                                @click="deletePicture(index, 1)"
                              >
                                <font-awesome-icon icon="times" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>Video</label>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-12">
                      <button
                        type="button"
                        class="btn btn-dark"
                        @click.prevent="showVideoChooser"
                      >
                        <span>
                          <font-awesome-icon icon="video" />
                          Browse Video</span
                        >
                      </button>
                      <input
                        style="display: none"
                        ref="input_video"
                        type="file"
                        name="video"
                        accept="video/*"
                        @change="setVideo"
                      />
                    </div>
                    <div class="col-md-12 mt-2" v-if="videoUpload">
                      <video
                        ref="videoPreview"
                        controls
                        style="width: 50%; border-radius: 10px"
                      ></video>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>Informasi Tambahan</label>
                </div>
                <div class="col-md-8">
                  <textarea
                    v-model="form.informasi_tambahan"
                    class="form-control"
                    rows="4"
                  ></textarea>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-12">
                  <a
                    href="javascript:window.history.go(-1);"
                    class="btn btn-secondary"
                  >
                    Kembali
                  </a>
                  <button
                    @click="submitForm()"
                    class="btn btn-primary float-end"
                    type="button"
                    :disabled="this.loading"
                  >
                    <font-awesome-icon
                      v-if="this.loading"
                      icon="circle-notch"
                      :spin="true"
                    />
                    Kirim
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      form: {
        usulan_berkala: "Perbaikan Bangunan Irigasi",
        nama_surveyor: "",
        daerah_irigasi: "",
        tahun_survey: "",
        informasi_tambahan: "",
      },
      nama_daerah_irigasi: "",
      videoUpload: "",
      formValidate: [],
      loading: false,
      hasilFoto: [],
      hasilVideoSketsa: [],
      listBangunan: [],
      prosesKirim: {},
    };
  },
  created() {
    this.loadFormFromLocalStorage();
    // this.fetchIrigasi();
  },
  methods: {
    submitForm() {
      this.loading = true;
      const formData = new FormData();
      formData.append("usulan_berkala", this.form.usulan_berkala);
      formData.append("nama_surveyor", this.form.nama_surveyor);
      formData.append("daerah_irigasi", this.nama_daerah_irigasi);
      formData.append("tahun_survey", this.form.tahun_survey);
      formData.append("informasi_tambahan", this.form.informasi_tambahan);
      if (this.videoUpload) {
        formData.append("video", this.videoUpload);
      }
      if (this.hasilFoto.length > 0) {
        for (var i = 0; i < this.hasilFoto.length; i++) {
          var foto = this.hasilFoto[i];
          formData.append("foto_lokasi[]", foto.url);
        }
      }

      axios
        .post("/v1/foto_video/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            // console.log(response.data.message);
            Swal.fire({
              title: "Sukses",
              text: "Data Berhasil Dikirim",
              icon: "success",
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              this.$router.go(-1);
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    setImage(e) {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        const imageDataURL = reader.result; // Mendapatkan base64 dari gambar
        const timestamp = Date.now();
        const imageName = `gambar_${timestamp}.png`;
        this.hasilFoto.push({ url: imageDataURL, name: imageName });
      };

      reader.readAsDataURL(file);
    },
    setVideo(e) {
      const file = e.target.files[0];
      this.videoUpload = e.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        const videoElement = this.$refs.videoPreview; // Referensi ke elemen video di template Anda
        videoElement.src = reader.result;
      };

      reader.readAsDataURL(file);
    },
    deletePicture(index, param) {
      if (param == 1) {
        this.hasilFoto.splice(index, 1);
      } else {
        this.hasilFotoSketsa.splice(index, 1);
      }
    },
    showImageChooser() {
      this.$refs.input_foto.click();
    },
    showVideoChooser() {
      this.$refs.input_video.click();
    },
    loadFormFromLocalStorage() {
      const savedFormsData = localStorage.getItem("formsData");

      if (savedFormsData) {
        const formsData = JSON.parse(savedFormsData);
        this.form.nama_surveyor = formsData.nama_surveyor;
        this.form.daerah_irigasi = formsData.daerah_irigasi;
        this.nama_daerah_irigasi = formsData.nama_daerah_irigasi;
        this.form.tahun_survey = formsData.tahun_survey;
      }
    },
  },

  beforeDestroy() {
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((track) => track.stop());
    }
  },
};
</script>

<style scoped>
.no-link {
  text-decoration: none;
}
.box {
  border-top: solid 5px #2968a0;
  border-bottom: solid 5px #0099ff;
}
.skeleton-loader {
  width: 100%;
  height: 20px;
  display: block;
  border-radius: 3px;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #d3d3d3;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}

.image-groups {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
}

/* Image styling */
.image-container {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
}

.image-item {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  object-fit: cover;
  margin-right: 5px;
}

.tombol-hapus {
  float: right;
  color: rgb(255, 0, 0);
  border-radius: 3px;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}
.tombol-kirim {
  float: right;
  color: #fff;
  border-radius: 3px;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0098fe;
}
</style>
